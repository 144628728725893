import { createStoreon, StoreonModule } from 'storeon';
import { PrevAddress } from 'modals/CreateAddress';
import formatISO from 'date-fns/formatISO';
import { AppDataQuery, OrderPaymentMethod } from './generated/graphql';

export const STORE_KEY = '@store-v1';

const defaultNow = new Date();

type CartItem = {
  productId: number;
  quantity: number;
  source: string | undefined;
};

export type CartState = CartItem[];

type CartIncreaseData = {
  readonly id: number;
  readonly counterInit: number;
  readonly counterStep: number;
  readonly price: number;
};

type CartDecreaseData = {
  readonly id: number;
  readonly counterStep: number;
};

export type Modal =
  | { type: 'support' }
  | { type: 'changeActiveAddress'; navigateTo?: string }
  | { type: 'createAddress'; navigateTo?: string; prevAddress?: PrevAddress; title?: string }
  | { type: 'enterPromoCode' }
  | { type: 'changeProductReview'; id: number; rating: number; body?: string }
  | { type: 'changePaymentMethod' }
  | { type: 'deliveryTimePicker' }
  | { type: 'shareCart'; urlToSend: string }
  | { type: 'addToCart'; cartItems: CartState }
  | { type: 'promoCodeActivated'; promoCode: string }
  | { type: 'updatePassword'; title?: string };

export type State = {
  readonly initialStateLoaded: boolean;
  readonly appData: AppDataQuery;
  readonly user: AppDataQuery['viewer']['user'];
  readonly deliveryIntervals: AppDataQuery['viewer']['deliveryIntervals'];
  readonly token: string | null;
  readonly cartItems: readonly CartItem[];
  readonly deliveryAt: string | null;
  readonly promoCode: string | null;
  readonly currentCategorySelectorId: number | null;
  readonly newMessage: boolean;
  readonly regionId: number | null;
  readonly closedLastOrderId: number | null;
  readonly visibleSubscribe: boolean;
  readonly modal: null | Modal;
  readonly now: Date;
  readonly inApp: boolean;
  readonly paymentMethod: OrderPaymentMethod | null;
  readonly subscriptionConnected: boolean | null;
};

type InitialState = State & {
  readonly cart: {
    readonly addedIds: number[];
    readonly quantityById: { [key: number]: number };
  };
};

export type Events = {
  setInitialState: InitialState;
  restoreState: Partial<State>;
  setToken: State['token'];
  removeCartItem: number;
  increaseCartItemQuantity: CartIncreaseData;
  decreaseCartItemQuantity: CartDecreaseData;
  clearCart: null;
  replaceAllCart: CartState;
  addCartItems: CartState;
  setPromoCode: State['promoCode'];
  setDeliveryAt: State['deliveryAt'];
  setCurrentCategorySelectorId: State['currentCategorySelectorId'];
  setNewMessage: State['newMessage'];
  setRegionId: number;
  setClosedLastOrder: State['closedLastOrderId'];
  setVisibleSubscribe: boolean;
  setNow: Date;
  setModal: null | Modal;
  setInApp: boolean;
  setPaymentMethod: OrderPaymentMethod;
  setAppData: AppDataQuery;
  setSubscriptionConnected: State['subscriptionConnected'];
};

const initialState: State = {
  initialStateLoaded: false,
  cartItems: [],
  token: null,
  deliveryAt: formatISO(new Date()),
  promoCode: null,
  currentCategorySelectorId: null,
  newMessage: false,
  regionId: null,
  closedLastOrderId: null,
  visibleSubscribe: true,
  now: defaultNow,
  modal: null,
  inApp: false,
  paymentMethod: null,
  appData: {
    // @ts-ignore
    viewer: {
      id: 'viewer',
      user: null,
      nearestDeliveryInterval: null,
      deliveryIntervals: [],
      regions: [],
    },
  },
  subscriptionConnected: null,
};

const changeCartQuantity = (items: readonly CartItem[], productId: number, quantity: number) => {
  const idx = items.findIndex((i) => i.productId === productId);
  if (idx === -1) {
    return [...items, { productId, quantity, source: undefined }];
  }

  if (quantity === 0) {
    return [...items.slice(0, idx), ...items.slice(idx + 1)];
  }

  return items.map((i) => {
    if (i.productId !== productId) return i;

    return { ...i, quantity };
  });
};

const getQuantityById = (items: readonly CartItem[], id: number) =>
  items.find((i) => i.productId === id)?.quantity || 0;

const storeModule: StoreonModule<State, Events> = (store) => {
  store.on('@init', () => initialState);

  store.on('restoreState', (_, newState = initialState) => newState);

  store.on('setToken', (_, token) => ({ token }));

  store.on('removeCartItem', (state, id) => ({
    cartItems: state.cartItems.filter((p) => p.productId !== id),
  }));

  store.on('increaseCartItemQuantity', (state, product) => {
    const oldQuantity = getQuantityById(state.cartItems, product.id);

    const counterInit = product.counterInit < 1 ? 1 : product.counterInit;
    const counterStep = product.counterStep < 1 ? 1 : product.counterStep;

    const newQuantity = oldQuantity > 0 ? oldQuantity + counterStep : counterInit;

    return {
      cartItems: changeCartQuantity(state.cartItems, product.id, newQuantity),
    };
  });

  store.on('decreaseCartItemQuantity', (state, product) => {
    const oldQuantity = getQuantityById(state.cartItems, product.id);
    const counterStep = product.counterStep < 1 ? 1 : product.counterStep;

    let newQuantity = oldQuantity - counterStep;

    if (newQuantity < counterStep) {
      newQuantity = 0;
    }

    return {
      cartItems: changeCartQuantity(state.cartItems, product.id, newQuantity),
    };
  });

  store.on('clearCart', () => ({
    cartItems: [],
  }));

  store.on('setPromoCode', (_, promoCode) => ({ promoCode }));

  store.on('setDeliveryAt', (_, deliveryAt) => ({ deliveryAt }));

  store.on('setCurrentCategorySelectorId', (_, currentCategorySelectorId) => ({ currentCategorySelectorId }));

  store.on('setNewMessage', (_, newMessage) => ({ newMessage }));

  store.on('setRegionId', (_, regionId) => ({ regionId }));

  store.on('setClosedLastOrder', (_, closedLastOrderId) => ({ closedLastOrderId }));

  store.on('setVisibleSubscribe', (_, visibleSubscribe) => ({ visibleSubscribe }));

  store.on('setNow', (_, now) => ({ now }));

  store.on('setModal', (_, modal) => ({ modal }));

  store.on('setInApp', (_, inApp) => ({ inApp }));

  store.on('setPaymentMethod', (_, paymentMethod) => ({ paymentMethod }));

  store.on('setSubscriptionConnected', (_, subscriptionConnected) => ({ subscriptionConnected }));

  store.on('setAppData', (_, appData) => ({
    appData,
    user: appData.viewer.user,
    deliveryIntervals: appData.viewer.deliveryIntervals,
  }));

  store.on('setInitialState', (state, st) => {
    if (state.initialStateLoaded) return {};

    const newState: State = { ...state, ...st, initialStateLoaded: true };

    if (!st.cart) {
      return newState;
    }

    const cartItems: CartState = st.cart.addedIds.map((productId) => ({
      productId,
      quantity: st.cart.quantityById[productId],
      source: undefined,
    }));

    return { ...newState, cartItems };
  });

  store.on('replaceAllCart', (_, cartItems) => ({ cartItems }));

  store.on('addCartItems', (state, items) => {
    const updatedCart = items.filter((i) => !state.cartItems.some((ci) => ci.productId === i.productId));
    return { cartItems: [...state.cartItems, ...updatedCart] };
  });
};

export const store = createStoreon<State, Events>([storeModule]);
