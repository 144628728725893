import React from 'react';
import { gql, Provider } from 'urql';
import { pipe, subscribe } from 'wonka';
import { SentryProvider } from 'sentry-react-lazy';
import { StoreContext } from 'storeon/react';
import { Ping } from 'components/Ping';
import { useCartFromUrl, useStoreon, usePromoFromUrl, useTokenFromUrl, useInApp } from './hooks';
import { createClient } from './client';
import { SaveStoreState } from './components/SaveStoreState';
import { AppDataQueryDocument } from './generated/graphql';
import { store } from './store';

const getStorageItem = (key: string) => {
  // @ts-ignore
  if (typeof localStorage !== 'undefined') {
    return localStorage.getItem(key);
  }
  return null;
};

const PromoFromUrl = () => {
  usePromoFromUrl();
  return null;
};

const AppWrapper: React.FC<{}> = ({ children }) => {
  useTokenFromUrl();
  useInApp();

  const getCartFromUrl = useCartFromUrl();
  const { token, dispatch } = useStoreon('token');
  const client = React.useMemo(() => createClient(token), [token]);
  const unsubscribeRef = React.useRef<() => void>();

  const loadAppData = () => {
    if (unsubscribeRef.current) {
      unsubscribeRef.current();
    }

    const { unsubscribe } = pipe(
      client.query(AppDataQueryDocument),
      subscribe((result) => {
        if (result.data) {
          dispatch('setAppData', result.data);

          // reset token
          // токен есть, но от апи пришел null, значит токен дохлый
          if (token && !result.data.viewer.user) {
            // eslint-disable-next-line no-console
            console.log('reset token: token exists and viewer.user is null');
            dispatch('setToken', null);
          }
        }
      }),
    );

    unsubscribeRef.current = unsubscribe;
    return unsubscribe;
  };

  // todo: add setInterval
  React.useEffect(loadAppData, [client, dispatch, token]);

  React.useEffect(() => {
    const savedToken = getStorageItem('fftoken'); // legacy
    const savedState = JSON.parse(getStorageItem('ffstore') || '{}');
    dispatch('setInitialState', { token: savedToken, ...savedState });
  }, [dispatch]);

  React.useEffect(() => {
    if (getCartFromUrl) {
      getCartFromUrl();
    }
  }, [getCartFromUrl]);

  return (
    <React.StrictMode>
      <Provider value={client}>
        {children}
        <SaveStoreState />
        <PromoFromUrl />
        <Ping />
      </Provider>
    </React.StrictMode>
  );
};

const sentryConfig = {
  dsn: 'https://943f3e075bf1468a86e3d477bf603802@o104774.ingest.sentry.io/5801880',
  debug: process.env.NODE_ENV !== 'production',
};

export const wrapPageElement = ({ element }: { element: any }) => (
  <SentryProvider
    url="https://browser.sentry-cdn.com/6.17.5/bundle.min.js"
    integrity="sha384-5PVJQx34z/S1Zn9wtOgdb3CzxQFtLgwlOXePLfQVgidQlYvmtc1XBNwNNwwUUhgG"
    config={sentryConfig}
  >
    <StoreContext.Provider value={store}>
      <AppWrapper>{element}</AppWrapper>
    </StoreContext.Provider>
  </SentryProvider>
);

gql`
  mutation CheckPromo($input: CheckPromoCodeInput!) {
    checkPromoCode(input: $input) {
      ... on CheckPromoCodePayload {
        useful
        message
      }
    }
  }
`;
