import {
  createClient as createClientBase,
  dedupExchange,
  fetchExchange,
  subscriptionExchange,
  ClientOptions,
} from 'urql';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { authExchange } from '@urql/exchange-auth';
import { cacheExchange } from '@urql/exchange-graphcache';
import { gql } from '@urql/core';
import { store } from 'store';
import schema, { ChatQueryDocument } from './generated/graphql';

// @ts-ignore
import { publicBaseUrl, path as graphqlPath } from './graphqlsource';

let endpointPath = '/graphql';

if (typeof window !== 'undefined' && window.location.pathname.includes('/dynamic/')) {
  endpointPath += '/raw';
}

export const createClient = (token: string | null) => {
  const config: ClientOptions = {
    url: endpointPath,
    exchanges: [
      dedupExchange,
      cacheExchange({
        schema,
        keys: {
          // @ts-ignore
          Alert: (data) => data.message,
          BindedCard: () => null,
          Features: () => null,
          PlaceOrderPayload: () => 'placeOrder',
        },
        optimistic: {
          // @ts-ignore
          toggleFavoriteProduct: (variables, cache) => {
            const data = cache.readFragment(
              gql`
                fragment _ on Query {
                  viewer {
                    user {
                      id
                      favoriteProductIds
                    }
                  }
                }
              `,
              { id: 'viewer' },
            );
            const user = data?.viewer?.user;
            if (!user) {
              return {
                __typename: 'ErrorPayload',
                message: 'user not found',
              };
            }

            let ids: number[] = [];
            // @ts-ignore
            const id: number = variables.input.id; // eslint-disable-line

            if (user.favoriteProductIds.includes(id)) {
              ids = user.favoriteProductIds.filter((i: number) => id !== i);
            } else {
              ids = [...user.favoriteProductIds, id];
            }

            return {
              __typename: 'ToggleFavoriteProductPayload',
              user: {
                __typename: 'User',
                id: user.id,
                favoriteProductIds: ids,
              },
            };
          },
        },
        updates: {
          Subscription: {
            chatMessageSent: (result, args, cache) => {
              const message: any = result.chatMessageSent;

              if (message.__typename !== 'ChatMessage') return;

              cache.updateQuery({ query: ChatQueryDocument }, (data: any): any => {
                // eslint-disable-next-line no-param-reassign
                data.viewer.user.chatMessages = [message, ...data.viewer.user.chatMessages];
                return data;
              });
            },
          },
        },
      }),
      authExchange<{ token: string }>({
        addAuthToOperation: ({ operation }) => {
          // тут костыль, потому нужно разобраться как правильно
          // urql хочет пересоздавать client после смены token
          // но это неудобно, когда нужно выполнить авторизацию и потом сразу мутацию
          if (!token) {
            return operation;
          }
          // fetchOptions can be a function (See Client API) but you can simplify this based on usage
          const fetchOptions =
            typeof operation.context.fetchOptions === 'function'
              ? operation.context.fetchOptions()
              : operation.context.fetchOptions || {};
          return {
            ...operation,
            context: {
              ...operation.context,
              fetchOptions: {
                ...fetchOptions,
                headers: {
                  ...fetchOptions.headers,
                  Authorization: `Bearer ${token}`,
                },
              },
            },
          };
        },
        getAuth: async () => null,
        // if (!authState) {
        //   const token = localStorage.getItem('fftoken');
        //   console.log('extract token', token);
        //   if (token) {
        //     console.log('get authState', token);
        //     return { token };
        //   }
        // }
        // console.log('reset');
        // localStorage.removeItem('fftoken');
        //   return null;
        // },
        didAuthError: ({ error }) => {
          // eslint-disable-next-line no-console
          console.log(error);

          const status = error.response?.status || 200;
          const hasError = status === 401 || status === 403 || !!error.message.match(/role "(.*?)" does not exist/);

          if (hasError) {
            // notification.error({
            //   message: 'Ошибка авторизации',
            //   description: error.message,
            // });
          }

          return hasError;
        },
      }),
      fetchExchange,
    ],
  };

  // skip subscription exchange is SSR mode
  if (typeof window !== 'undefined') {
    const subUrl = `${publicBaseUrl}${graphqlPath}`
      .replace('https://', 'wss://')
      .replace('http://', 'ws://')
      .replace('/withintrospection', '');

    // eslint-disable-next-line no-console
    console.log('subscription url', subUrl);

    const subscriptionClient = new SubscriptionClient(subUrl, {
      reconnect: true,
    });

    subscriptionClient.onError(() => {
      store.dispatch('setSubscriptionConnected', false);
    });

    subscriptionClient.onConnected(() => {
      store.dispatch('setSubscriptionConnected', true);
    });

    config.exchanges?.push(
      subscriptionExchange({
        forwardSubscription(operation) {
          return subscriptionClient.request(operation);
        },
      }),
    );
  }

  return createClientBase(config);
};
