import * as React from 'react';

declare global {
  interface Window {
    gtag: any;
    ym: any;
  }
}

export type AddToCart = {
  readonly id: number;
  readonly name: string;
  readonly price: number;
};

type AddToFavorite = {
  readonly id: number;
  readonly name: string;
  readonly price: number;
};

type Purchase = {
  readonly id: number;
  readonly totalSum: number;
  readonly products: Readonly<Array<{ id: number; name: string; price: number; quantity: number }>>;
};

const make =
  (key: string) =>
  (...args: any) => {
    // @ts-ignore
    if (window[key]) {
      // @ts-ignore
      window[key](...args);
    }
  };

const fbq = make('fbq');
const gtag = make('gtag');
const ym = make('ym');

export const yaId = 78284950;

const dataLayer = (data: any) => {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push(data);
};

export const addToCart = (data: AddToCart, quantity: number) => {
  gtag('event', 'add_to_cart', {
    value: 1,
    id: data.id,
    name: data.name,
    totalSum: data.price * quantity,
    quantity: 1,
  });

  gtag('event', 'ADD_TO_CART');

  fbq('track', 'AddToCart', {
    currency: 'RUB',
    content_type: 'product',
    content_ids: [data.id],
    value: data.price,
  });

  dataLayer({
    ecommerce: {
      add: {
        products: [
          {
            id: data.id,
            name: data.name,
            price: data.price,
            quantity: 1,
          },
        ],
      },
    },
  });

  ym(yaId, 'reachGoal', 'ADD_TO_CART');
};

export const addToFavorite = ({ id, name, price }: AddToFavorite) => {
  gtag('event', 'add_to_wishlist', {
    value: 1,
    id,
    name,
    price,
  });

  gtag('event', 'favorite');

  fbq('track', 'AddToWishlist', {
    currency: 'RUB',
    content_type: 'product',
    content_ids: [id],
    content_name: name,
    value: price,
  });

  ym(yaId, 'reachGoal', 'favorite');
};

export const purchase = (data: Purchase) => {
  gtag('event', 'purchase', {
    items: data,
  });

  gtag('event', 'Purchase');

  dataLayer({
    ecommerce: {
      purchase: {
        actionField: {
          id: data.id,
        },
        products: data.products,
      },
    },
  });

  ym(yaId, 'reachGoal', 'Purchase');

  fbq('track', 'Purchase', {
    currency: 'RUB',
    content_type: 'product',
    value: data.totalSum,
    contents: data.products.map((i) => ({
      id: i.id,
      quantity: i.quantity,
    })),
  });
};

export const clickSocial = (label: string) => {
  gtag('event', 'social_share', {
    event_label: label,
  });
};

export const clickBanner = () => {
  gtag('event', 'click_banner');
  ym(yaId, 'reachGoal', 'click_banner');
};

export const userLogin = () => {
  gtag('event', 'SIGNIN');
  ym(yaId, 'reachGoal', 'SIGNIN');
  fbq('track', 'SIGNIN');
};

export const userReg = () => {
  gtag('event', 'SIGNUP');
  ym(yaId, 'reachGoal', 'SIGNUP');
  fbq('track', 'SIGNUP');
};

export const userScrollMain = () => {
  gtag('event', '1');
  ym(yaId, 'reachGoal', '1');
};

export const userClickButtonCheckout = () => {
  gtag('event', 'button_checkout');
  fbq('track', 'InitiateCheckout');
};

export const userClickButtonOrderConfirm = () => {
  gtag('event', 'button_confirm');
};

export const userClickButtonPayment = () => {
  gtag('event', 'button_payment');
};

export const useAnalyticsScroll = () => {
  React.useEffect(() => {
    let isScroll = false;

    const scrollToEnd = (e: Event) => {
      const currentScrollHeight = (<HTMLDocument | null>e.target)?.body?.scrollHeight;
      if (currentScrollHeight && window.scrollY + window.innerHeight > currentScrollHeight / 2 && !isScroll) {
        userScrollMain();
        isScroll = true;
      }
    };

    window.addEventListener('scroll', scrollToEnd);

    return () => window.removeEventListener('scroll', scrollToEnd);
  }, []);
};
