import * as React from 'react';
import { usePingMutation } from 'generated/graphql';
import { useDebouncedState, useStoreon } from 'hooks';
import { useSentry } from 'sentry-react-lazy';
import { gql } from 'urql';

export const Ping = () => {
  const { dispatch, cartItems } = useStoreon('cartItems');
  const [debouncedCartItems] = useDebouncedState(cartItems, 10000);
  const sentry = useSentry();
  const [, pingMutate] = usePingMutation();

  React.useEffect(() => {
    if (!debouncedCartItems) return undefined;

    const mutateFn = async () => {
      const input = {
        device: navigator.userAgent,
        cartItems: debouncedCartItems,
      };

      try {
        const res = await pingMutate({ input });
        if (res.error) {
          throw res.error;
        }
        if (res.data?.result.__typename === 'ErrorPayload') {
          throw new Error(res.data.result.message);
        }

        if (res.data) {
          dispatch('setNow', res.data.result.now);
        }
      } catch (err) {
        sentry.captureException(new Error(`failed to ping: ${err.message}`));
      }
    };

    mutateFn();

    const t = setInterval(mutateFn, 60000);

    return () => clearInterval(t);
  }, [debouncedCartItems, dispatch, sentry, pingMutate]);

  return null;
};

gql`
  mutation PingMutation($input: PingInput!) {
    result: ping(input: $input) {
      __typename

      ... on PingPayload {
        now
      }

      ... on ErrorPayload {
        message
      }
    }
  }
`;
