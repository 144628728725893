import qs from 'query-string';
import format from 'date-fns/format';
import locale from 'date-fns/locale/ru';
import parseISO from 'date-fns/parseISO';

export const graphql = (s: any) => s;

export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null;
}

export const getQueryParams = (): ReturnType<typeof qs.parse> => {
  if (typeof window !== 'undefined') {
    return qs.parse(window.location.search);
  }
  return {};
};

export function addUniq<T>(items: readonly T[], value: T) {
  if (items.indexOf(value) !== -1) {
    return items;
  }

  return [...items, value];
}

export const remove = <T, K extends keyof T>(key: K, obj: T): Omit<T, K> => {
  const { [key]: del, ...data } = obj;
  return data;
};

export const normalizePhone = (val: string) => {
  const nv = val.replace(/[^\d]/g, '');

  if (nv.length === 10) {
    return `7${nv}`;
  }

  if (nv[0] === '8' && nv.length === 11) {
    return `7${nv.substr(1)}`;
  }

  return nv;
};

export const formatPhone = (v: string) => {
  const m = v.match(/7([\d]{3})([\d]{3})([\d]{2})([\d]{2})/);

  if (m) {
    return `+7 (${m[1]}) ${m[2]} ${m[3]} ${m[4]}`;
  }

  return v;
};

export const captureException = (err: Error) => {
  // eslint-disable-next-line no-console
  console.log(err);
};

export const formatVolume = (volume: number) => {
  const v = Math.round(volume * 1000) / 1000;

  return v < 1 && v !== 0.5 && v !== 0.75 ? `${v * 1000} мл` : `${v} л`;
};

export const formatWeight = (weight: number) => {
  const v = Math.round(weight * 1000) / 1000;

  return v < 1 && v !== 0.5 ? `${v * 1000} г` : `${v} кг`;
};

export const formatQuantity = (
  priceUnit: 'KG' | 'PCS' | 'PACK',
  weight: number | null | undefined,
  volume: number | null | undefined,
) => {
  if (priceUnit === 'PCS' || priceUnit === 'PACK') {
    const valuesStr = [weight && formatWeight(weight), volume && formatVolume(volume)].filter(Boolean).join(', ');
    const valuesLabel = valuesStr.length > 0 ? `(${valuesStr})` : '';

    return `1 ${priceUnit === 'PCS' ? 'шт' : 'уп'} ${valuesLabel}`;
  }

  if (!weight) return null; // для TS. При priceUnit === 'KG' weight будет всегда number

  return formatWeight(weight);
};

export const formatDay = (v: string): string => format(parseISO(v), 'd MMMM', { locale });

export const formatFullDate = (v: string): string => format(parseISO(v), 'd MMMM yyyy', { locale });

export const validatePhone = (phone: string): boolean => normalizePhone(phone).length === 11;

export const validateFullName = (fullName: string): boolean => {
  const splitFullName = fullName.trim().split(' ');
  return splitFullName.length === 3 && splitFullName.findIndex((item) => item.length < 3) === -1;
};

export const validateEmail = (val: string) => {
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(val.toLowerCase());
};

export const withCounter = (title: string, count: number) => (count > 0 ? `${title} (${count})` : title);

export const NBSP = '\xa0';

export const formatMoney = (value: number): string =>
  value
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    .replace(' ', NBSP)
    .replace(',00', '');

export const calcDiscount = (price: number, oldPrice: number) => oldPrice && 100 - Math.round((price / oldPrice) * 100);

export const groupBy = <T>(data: readonly T[], fn: (v: T) => string) => {
  const groups: { [key: string]: T[] } = {};
  data.forEach((i) => {
    const k = fn(i);
    if (!groups[k]) {
      groups[k] = [];
    }
    groups[k].push(i);
  });
  return groups;
};

// форматирует время из количества секунд с начала суток
export const formatNumberTime = (v: number): string => {
  const minutes = Math.round(v / 60);
  return [Math.round(minutes / 60), minutes % 60].map((n) => n.toString().padStart(2, '0')).join(':');
};

export const maxTime = (a: string | null, b: string | null): string => {
  if (!a && !b) {
    return new Date().toISOString();
  }

  const ad = a ? Date.parse(a) : new Date();
  const bd = b ? Date.parse(b) : new Date();

  return new Date(Math.max(+ad, +bd)).toISOString();
};

export const formatTimeDelivery = (deliveryAt: string): string[] => {
  const day = formatDay(deliveryAt);
  const time = new Date(deliveryAt).getHours().toString();
  const prevTime = time.length > 1 ? `${time}:00` : `0${time}:00`;
  const nextTime = `${new Date(Date.parse(deliveryAt) + 7200000).getHours()}:00`;
  return [day, prevTime, nextTime];
};

export const formatTimeFromNumber = (value: number) => {
  const numberValue = value > 0 ? value : 0;
  const h = Math.floor(numberValue / 60);
  const m = Math.floor(numberValue % 60);
  return `${h > 0 ? `${h} ч ` : ' '} ${m > 0 ? `${m} мин` : ''}`;
};

type Words = [string, string, string];
export const getNumWord = (count: number, words: Words) => {
  const number = Math.abs(count) % 100;
  const num = number % 10;

  if (count > 10 && count < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num === 1) return words[0];
  return words[2];
};

export const getNumWordWithCount = (count: number, words: Words) => {
  const word = getNumWord(count, words);
  return `${count} ${word}`;
};

export const getNumberArray = (count: number) => Array.from(Array(count).keys());

export const splitSeconds = (v: number) => [
  Math.min(Math.floor(v / 3600), 99),
  Math.floor(v / 60) % 60,
  v % 60,
];
