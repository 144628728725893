/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import './src/global.css';
import { yaId } from './src/analytics';
export { wrapPageElement } from './src/gatsbyPlugins';

export const onRouteUpdate = function ({ location }) {
  if (
    process.env.NODE_ENV === `production` &&
    typeof window.ym !== `undefined` &&
    location
  ) {
    const { pathname, search, hash } = location;
    window.ym(yaId, "hit", pathname + search + hash);
  }
};

