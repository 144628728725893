import * as React from 'react';
import { gql } from '@urql/core';
import { useStoreon as useBaseStoreon, StoreContext } from 'storeon/react';
import { StoreonDispatch } from 'storeon';
import { useLocation } from '@reach/router';
import qs from 'query-string';
import { State, CartState, Events } from 'store';
import {
  OrderPaymentMethod,
  UploadImageType,
  useBasicCreateUploadImageUrlMutation,
  useBasicDeleteUploadImageMutation,
  useCheckPromo,
  useDryOrderQuery,
} from './generated/graphql';
import { maxTime } from './utils';

export const useStoreon = (...keys: (keyof State)[]) => useBaseStoreon<State, Events>(...keys);

export const useWindowSize = () => {
  const [size, setSize] = React.useState([0, 0]);
  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const useIsClient = () => {
  const { token } = useStoreon('token');
  return !!token;
};

export const useIsMounted = () => {
  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  return hasMounted;
};

export const useFavorite = (productId: number) => {
  const { appData } = useStoreon('appData');

  return appData.viewer.user && appData.viewer.user.favoriteProductIds.includes(productId);
};

const now = new Date();

export type DryOrderVariables = NonNullable<NonNullable<Parameters<typeof useDryOrderQuery>[0]>['variables']>;
export const useDryOrder = (): [...ReturnType<typeof useDryOrderQuery>, DryOrderVariables] => {
  const {
    cartItems,
    promoCode,
    deliveryAt: selectedDeliveryAt,
    appData,
  } = useStoreon('cartItems', 'promoCode', 'deliveryAt', 'appData');
  const nearestDelivery = appData.viewer.nearestDeliveryInterval?.value || now;
  const addressId = appData.viewer.user?.activeAddress?.id || 0;
  const ecoPack = appData.viewer.user?.lastOrder?.ecoPack || false;
  const asap = appData.viewer.user?.lastOrder?.asap || false;
  const withoutOverweight = appData.viewer.user?.lastOrder?.withoutOverweight || false;

  const deliveryAt = maxTime(selectedDeliveryAt, nearestDelivery);

  const variables = React.useMemo(
    () => ({
      input: {
        addressId,
        deliveryAt,
        paymentMethod: OrderPaymentMethod.Online,
        origin: 'web-v2',
        items: cartItems,
        comment: '',
        asap,
        ecoPack,
        withoutOverweight,
        promoCodes: promoCode ? [promoCode] : [],
      },
    }),
    [cartItems, promoCode, deliveryAt, addressId, ecoPack, asap, withoutOverweight],
  );

  const res = useDryOrderQuery({
    requestPolicy: 'cache-and-network',
    pause: cartItems.length === 0,
    variables,
  });

  if (cartItems.length === 0) {
    return [{ data: undefined, error: undefined, fetching: false, stale: false }, () => null, variables];
  }

  return [...res, variables];
};

export const dryOrderQuery = gql`
  query DryOrderQuery($input: PlaceOrderInput!) {
    viewer {
      id
      dryOrder(input: $input) {
        __typename
        ... on PlaceOrderPayload {
          order {
            __typename
            id
            itemSum
            deliveryCost
            totalSum
            items {
              id
              productId
              quantity
              product {
                ...ProductCard_product
              }
            }
          }

          recommendedProducts {
            ...ProductCard_product
          }

          alerts {
            type
            message
          }
        }
        ... on ErrorPayload {
          message
        }
      }
    }
  }
`;

export const getExtraDryOrderData = (res: ReturnType<typeof useDryOrder>[0]) => {
  if (!res.data || res.data.viewer.dryOrder.__typename !== 'PlaceOrderPayload') {
    return {
      itemCount: 0,
      canPlace: false,
    };
  }

  const { dryOrder } = res.data.viewer;

  return {
    itemCount: dryOrder.order.items.length,
    canPlace: !dryOrder.alerts.some((a) => a.type === 'ERROR'),
  };
};

export const usePromoFromUrl = () => {
  const { dispatch } = useStoreon();
  const location = useLocation();
  const { promo } = qs.parse(location.search);
  const [, mutation] = useCheckPromo();

  React.useEffect(() => {
    const checkPromo = async () => {
      if (typeof promo === 'string') {
        const res = await mutation({ input: { value: promo } });
        if (res.data?.checkPromoCode.__typename === 'CheckPromoCodePayload') {
          if (res.data?.checkPromoCode.useful) {
            dispatch('setPromoCode', promo);
            dispatch('setModal', { type: 'promoCodeActivated', promoCode: promo });
          }
        }
      }
    };
    checkPromo();
  }, [dispatch, mutation, promo]);
};

export const useTokenFromUrl = () => {
  const { dispatch } = useStoreon();
  const location = useLocation();
  const { token } = qs.parse(location.search);

  React.useEffect(() => {
    if (token && typeof token === 'string') {
      dispatch('setToken', token);
    }
  }, [dispatch, token]);
};

const decryptCartData = (data: string | string[] | null) => {
  if (typeof data === 'string') {
    // check if it is base64
    try {
      const decrypted = atob(data);
      return JSON.parse(decrypted);
    } catch (e) {
      return null;
    }
  }
  return null;
};

export const useCartFromUrl = () => {
  const { dispatch } = useStoreon();
  const [mounted, setMounted] = React.useState<boolean>(false);
  const location = useLocation();
  const { prods: encryptedItemList } = qs.parse(location.search);

  React.useEffect(() => {
    if (mounted) {
      const cart = decryptCartData(encryptedItemList);
      if (typeof cart === 'object' && cart !== null && Array.isArray(cart)) {
        const cartList: CartState = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const item of cart) {
          if (item) {
            const productId = Number(item.productId);
            const quantity = Number(item.quantity);
            const source = typeof item.source === 'string' || typeof item.source === 'undefined';
            if (productId && !Number.isNaN(productId) && quantity && !Number.isNaN(quantity) && source) {
              cartList.push({ productId, quantity, source: item.source });
            }
          }
        }
        dispatch('setModal', { type: 'addToCart', cartItems: cartList });
      }
    }
    return () => {
      setMounted(false);
    };
  }, [mounted, dispatch, encryptedItemList]);

  return () => setMounted(true);
};

export const useInApp = () => {
  const location = useLocation();
  const { inApp } = qs.parse(location.search);
  const { dispatch } = useStoreon();
  if (inApp !== undefined) {
    dispatch('setInApp', true);
  }
};

export const useDebouncedState = <T>(
  currentValue: T,
  time: number,
): [T | null, React.Dispatch<React.SetStateAction<T | null>>] => {
  const [debouncedValue, setDebouncedValue] = React.useState<T | null>(null);

  React.useEffect(() => {
    const t = setTimeout(() => setDebouncedValue(currentValue), time);
    return () => clearTimeout(t);
  }, [currentValue, time]);

  return [debouncedValue, setDebouncedValue];
};

const getCartItemQuantity = (state: Partial<State>, productId: number) => {
  if (!state.cartItems) {
    return 0;
  }

  const item = state.cartItems.find((i) => i.productId === productId);
  return item?.quantity || 0;
};

// оптимизированная версия useStoreon хука, которая не дергает лишние карточки
export const useCartItemQuantity = (productId: number): [number, StoreonDispatch<Events>] => {
  const store = React.useContext(StoreContext);

  const [quantity, setQuantity] = React.useState(getCartItemQuantity(store.get() as State, productId));

  const prev = React.useRef<number | null>(null);

  React.useEffect(
    () =>
      store.on('@changed', (_, changed: Partial<State>) => {
        if (changed.cartItems) {
          const q = getCartItemQuantity(changed, productId);

          if (prev.current !== q) {
            prev.current = q;
            setQuantity(q);
          }
        }
      }),
    [productId], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return [quantity, store.dispatch];
};

export const useCartItemsCount = () => {
  const store = React.useContext(StoreContext);

  const state = store.get() as State;

  const [count, setCount] = React.useState(state.cartItems.length);

  const prev = React.useRef<number | null>(null);

  React.useEffect(
    () =>
      store.on('@changed', (_, changed: Partial<State>) => {
        if (changed.cartItems && prev.current !== changed.cartItems.length) {
          prev.current = changed.cartItems.length;
          setCount(changed.cartItems.length);
        }
      }),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return count;
};

export type AttachedImage = {
  readonly id: number;
  readonly url: string;
};

type AttachDispatchActions = Readonly<{ type: 'showSelector' } | { type: 'reset' } | { type: 'remove'; id: number }>;

type AttachDispatch = (options: AttachDispatchActions) => void;

type ReturnAttachImages = {
  readonly images: AttachedImage[];
  readonly setAttachedImages: (files: FileList, type: UploadImageType) => void;
  readonly dispatch: AttachDispatch;
};

export const useAttachImages = (
  inputRef: React.RefObject<HTMLInputElement>,
  prevImages?: AttachedImage[],
): ReturnAttachImages => {
  const [images, setImages] = React.useState<AttachedImage[]>(prevImages || []);
  const [, createMutate] = useBasicCreateUploadImageUrlMutation();
  const [, deleteMutate] = useBasicDeleteUploadImageMutation();

  const setAttachedImages = async (files: FileList, type: UploadImageType) => {
    const arrImages = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      arrImages.push(files[i]);
    }

    await Promise.all(
      arrImages.map(async (img) => {
        const data = await createMutate({
          type,
          size: img.size,
        });

        if (data.__typename === 'CreateUploadImageUrlPayload') {
          const loadUrl =
            process.env.NODE_ENV === 'production'
              ? data.value
              : data.value.replace('https://familyfriendsite.freshdev.ru', 'http://localhost:8000');

          const response = await fetch(loadUrl, {
            method: 'POST',
            body: img,
          });

          const { id, url } = await response.json();
          setImages((i) => [...i, { id, url }]);
        }
      }),
      // eslint-disable-next-line no-alert
    ).catch((err) => alert(err.message));
  };

  const dispatch = async (options: AttachDispatchActions) => {
    const input = inputRef.current;

    if (options.type === 'reset') {
      if (input) {
        setImages([]);

        // eslint-disable-next-line no-param-reassign
        input.value = '';
      }
    }

    if (options.type === 'showSelector') {
      if (input) {
        input.click();
      }
    }

    if (options.type === 'remove') {
      const arrImages = images.filter((i) => options.id !== i.id);

      await deleteMutate({
        id: options.id,
      });

      setImages(arrImages);
    }
  };

  return { images, setAttachedImages, dispatch };
};

gql`
  mutation DeleteUploadImageMutation($input: DeleteUploadImageInput!) {
    result: deleteUploadImage(input: $input) {
      ... on ErrorPayload {
        message
      }
    }
  }

  mutation CreateUploadImageUrlMutation($input: CreateUploadImageUrlInput!) {
    result: createUploadImageUrl(input: $input) {
      ... on CreateUploadImageUrlPayload {
        value
      }

      ... on ErrorPayload {
        message
      }
    }
  }
`;
