const baseUrl = process.env.GATSBY_GRAPHQL_BASE_URL || 'https://familyfriendsite.freshdev.ru';
const publicBaseUrl = process.env.GATSBY_PUBLIC_BASE_URL || 'https://familyfriendsite.freshdev.ru';
const path = process.env.GATSBY_GRAPHQL_PATH || '/graphql';

// eslint-disable-next-line no-console
console.log('Base URL:', baseUrl);
// eslint-disable-next-line no-console
console.log('Public base URL:', publicBaseUrl);

const url = `${baseUrl}${path}`;

module.exports = {
  path,
  baseUrl,
  url,
  publicBaseUrl,
};
