// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-app-tsx": () => import("./../../../src/pages/about-app.tsx" /* webpackChunkName: "component---src-pages-about-app-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-agreement-tsx": () => import("./../../../src/pages/agreement.tsx" /* webpackChunkName: "component---src-pages-agreement-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-delivery-form-tsx": () => import("./../../../src/pages/delivery/form.tsx" /* webpackChunkName: "component---src-pages-delivery-form-tsx" */),
  "component---src-pages-dynamic-category-tsx": () => import("./../../../src/pages/_/dynamic/category.tsx" /* webpackChunkName: "component---src-pages-dynamic-category-tsx" */),
  "component---src-pages-dynamic-home-tsx": () => import("./../../../src/pages/_/dynamic/home.tsx" /* webpackChunkName: "component---src-pages-dynamic-home-tsx" */),
  "component---src-pages-dynamic-product-tsx": () => import("./../../../src/pages/_/dynamic/product.tsx" /* webpackChunkName: "component---src-pages-dynamic-product-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-tsx": () => import("./../../../src/pages/landing.tsx" /* webpackChunkName: "component---src-pages-landing-tsx" */),
  "component---src-pages-my-addresses-tsx": () => import("./../../../src/pages/my/addresses.tsx" /* webpackChunkName: "component---src-pages-my-addresses-tsx" */),
  "component---src-pages-my-bought-tsx": () => import("./../../../src/pages/my/bought.tsx" /* webpackChunkName: "component---src-pages-my-bought-tsx" */),
  "component---src-pages-my-deposit-tsx": () => import("./../../../src/pages/my/deposit.tsx" /* webpackChunkName: "component---src-pages-my-deposit-tsx" */),
  "component---src-pages-my-favorites-tsx": () => import("./../../../src/pages/my/favorites.tsx" /* webpackChunkName: "component---src-pages-my-favorites-tsx" */),
  "component---src-pages-my-order-details-tsx": () => import("./../../../src/pages/my/order-details.tsx" /* webpackChunkName: "component---src-pages-my-order-details-tsx" */),
  "component---src-pages-my-orders-tsx": () => import("./../../../src/pages/my/orders.tsx" /* webpackChunkName: "component---src-pages-my-orders-tsx" */),
  "component---src-pages-my-products-tsx": () => import("./../../../src/pages/my/products.tsx" /* webpackChunkName: "component---src-pages-my-products-tsx" */),
  "component---src-pages-my-settings-tsx": () => import("./../../../src/pages/my/settings.tsx" /* webpackChunkName: "component---src-pages-my-settings-tsx" */),
  "component---src-pages-neworder-tsx": () => import("./../../../src/pages/neworder.tsx" /* webpackChunkName: "component---src-pages-neworder-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-pay-tsx": () => import("./../../../src/pages/pay.tsx" /* webpackChunkName: "component---src-pages-pay-tsx" */),
  "component---src-pages-payment-tsx": () => import("./../../../src/pages/payment.tsx" /* webpackChunkName: "component---src-pages-payment-tsx" */),
  "component---src-pages-returns-tsx": () => import("./../../../src/pages/returns.tsx" /* webpackChunkName: "component---src-pages-returns-tsx" */),
  "component---src-pages-sberbank-finish-tsx": () => import("./../../../src/pages/_/sberbank/finish.tsx" /* webpackChunkName: "component---src-pages-sberbank-finish-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

