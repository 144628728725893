import * as React from 'react';
import { useStoreon } from 'hooks';

export const SaveStoreState = () => {
  const prevData = React.useRef<false | string>(false);
  const { initialStateLoaded, cartItems, promoCode, token, dispatch } = useStoreon('initialStateLoaded', 'cartItems', 'promoCode', 'token');
  const data = initialStateLoaded && JSON.stringify({ cartItems, promoCode, token });

  if (data && prevData.current !== data) {
    // eslint-disable-next-line no-console
    console.log('save store', data);
    localStorage.setItem('ffstore', data);
    prevData.current = data;
  }

  React.useEffect(() => {
    const handler = (e: StorageEvent) => {
      if (e.key !== 'ffstore' || e.newValue === e.oldValue) {
        return;
      }

      if (e.newValue) {
        const newState = JSON.parse(e.newValue);
        // eslint-disable-next-line no-console
        console.log('merge state from other tab', newState);
        dispatch('restoreState', newState);
      }
    };

    window.addEventListener('storage', handler);
    return () => window.removeEventListener('storage', handler);
  }, [dispatch]);

  return null;
};
